body {
  margin: 0;
  font-family: "AR One Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #b4d2df !important;
}

[class^="ant-input"],
[class*=" ant-input"] {
  font-family: "AR One Sans", sans-serif !important;
  font-weight: bold !important;
}

.AXMMain {
  font-size: 4rem;
}

.HeroMain {
  padding: 80px;
  color: #000000;
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: left;
  height: 100%;
}
.first {
  height: 100vh;
}
.title h1 {
  padding-bottom: 40px;
  padding-top: 40px;
  font-weight: bold;
  color: #000000;
}

.title p {
  font-weight: 600;
}

.btnGroupMain {
  margin-top: 30px;
  display: flex;
}

.buyaxm {
  background-color: #d52232;
  border-radius: 0.5rem;
  color: white;
  border: none;
  width: max-content;
  padding: 5px 20px;
}

.Presale_Guide {
  background: transparent;
  border: none;
  margin-left: 20px;
}

.rola {
  margin-left: -40px;
  overflow: hidden !important;
  width: 180px;
  z-index: -9;
  position: relative;
}

.squares {
  z-index: -9;
  position: relative;
}

.ChainBox {
  background-color: #ffffff;
  border-radius: 1rem;
  text-align: center;
  padding: 10px 20px 0px 20px;
}

.bgBar {
  background-color: #ab1926;
}

.progress {
  background-color: #b4d2df !important;
  border: 4px solid #d52232 !important;
  border-radius: 20px !important;
}

.chainHeader {
  background-color: #d52232;
  border-radius: 1rem 1rem 0 0;
  color: #ffffff;
  height: 7vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.chainHeader p {
  margin-bottom: 0;
}

.ChainBox h3 {
  padding-top: 20px;
  font-weight: bold;
}

.blue {
  color: #4794b5;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 20px 65px 0 65px;
}

.evmChains {
  display: flex;
  justify-content: space-evenly;
}

.evmChains button {
  width: max-content;
  margin-bottom: 12px;
}

.arb {
  background-color: transparent;
  border: 2px solid #4c8fac;
  border-radius: 8px;
  padding: 5px;
}

.solanaChain button {
  margin-bottom: 20px;
}

.solanaChain h5 {
  font-weight: 700;
}

.firesksk{
  margin-top: -63px;
    width: 34px;
}

.playReceiveMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.playReceive {
  height: auto;
  border-radius: 8px;
}

.selectMain{
  width: 100% !important;
}



:where(.css-dev-only-do-not-override-djtmh8).ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  display: flex !important;
  justify-content: space-between !important;
}

.ant-select .ant-select-selection-item {
  font-weight: bold !important;
  font-family: "AR One Sans", sans-serif !important;
}

.selectMain {
  height: 50px !important;
}
.playReceive h6 {
  text-align: left;
}

.plySpn {
  text-align: left !important;
  color: #636262 !important;
  font-weight: 600;
  padding: 10px 10px 0 10px;
}

.Selected {
  font-weight: 700;
}

.connectMAin {
  display: flex;
  justify-content: center;
}

.connectWallet {
  background-color: #d52232;
  border-radius: 0.5rem;
  color: white;
  border: none;
  width: max-content;
  padding: 10px 95px;
  /* margin-bottom: 20px; */
}

.spaceW {
  width: 100%;
}

.ant-input {
  padding: 10px 7px !important;
}

.ant-input-group {
  padding: 0 10px !important;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon {
  background-color: white !important;
  border-left: none !important;
}
.ant-input-outlined:hover {
  border: 1px solid rgb(122, 121, 121) !important;
}
.ant-input-outlined {
  border-right: none !important;
}

.supply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 90px 0;
}

.supply h2 {
  font-weight: 600;
}

.supply img {
  margin: 10px 0;
}

.supplyboxes {
  display: flex;
  margin-bottom: 50px;
}

.blueBox {
  background-color: #0550aa;
  color: white;
  padding: 5px 16px;
  font-size: 11px;
  border-radius: 13px;
  margin-right: 5px;
}

.brownBox {
  background-color: #b58e16;
  color: white;
  padding: 5px 16px;
  font-size: 11px;
  border-radius: 13px;
  margin-right: 5px;
}

.greenBox {
  background-color: #009d45;
  color: white;
  padding: 5px 16px;
  font-size: 11px;
  border-radius: 13px;
  margin-right: 5px;
}

.orangeBox {
  background-color: #ca5b18;
  color: white;
  padding: 5px 16px;
  font-size: 11px;
  border-radius: 13px;
  margin-right: 5px;
}

.redBox {
  background-color: #c82645;
  color: white;
  padding: 5px 16px;
  font-size: 11px;
  border-radius: 13px;
  margin-right: 5px;
}

.yellowBox {
  background-color: #cbd838;
  color: black;
  padding: 5px 16px;
  font-size: 11px;
  border-radius: 13px;
  margin-right: 5px;
}

.coinMarktBG{
  background-color: #DAE9EF;
  align-items: center;
  padding: 20px;
  border-radius: .6rem
}

.supply h2 {
  font-weight: bold;
}

.text_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.supplyFlx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cahinCard {
  background-color: #ffffff;
  background-image: url(../../assets/firstCard.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 20px 30px;
  border-radius: 2rem;
}

.cahinCardScnd {
  background-color: #ffffff;
  background-image: url(../../assets/ScndCard.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 20px 30px;
  border-radius: 2rem;
}

.cahinCardThrd {
  background-color: #ffffff;
  background-image: url(../../assets/ThrdCard.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 20px 30px;
  border-radius: 2rem;
}

.cahinCardForth {
  background-color: #ffffff;
  background-image: url(../../assets/ForthCard.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 20px 30px;
  border-radius: 2rem;
}

.cahinCardFifth {
  background-color: #ffffff;
  background-image: url(../../assets/Fifth.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 20px 30px;
  border-radius: 2rem;
}

.chainCradHeading {
  font-weight: bold;
  margin-top: 5px;
}

.chainCradPara {
  font-weight: 700;
  margin-bottom: 30px;
}

button:hover{
  animation: pulse 1s infinite;
  transition: 1.5s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(.9);
  }
    100% {
    transform: scale(1);
  }
}

.chainCradSpan {
  font-weight: 700;
}

.chainCradBtn {
  background: transparent;
  border: none;
}

.chainCardScndRow {
  margin-top: 40px;
}

.allWallet {
  border-right: 3px solid #d52232;
}

.scndModalCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.robot {
  margin-top: -120px;
  margin-right: -90px;
}

.modalemgs {
  font-size: 12px;
}

.modalemgs img {
  width: 50%;
  cursor: pointer;
}

.modal-content {
  border-radius: 1.5rem !important;
}

.toastModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-footer {
  border-top: none !important;
}

.closeHXN {
  background-color: #d52232 !important;
  border: none !important;
  width: max-content !important;
}

.allWallet h4,
.allWalletPara {
  text-align: left;
}

.spaceBox {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #d1d1d1;
}

.Available{
  margin-top: 280px;
  margin-bottom: 60px;
}

.Available h2{
  text-align: center;
  font-weight: 800;
  padding-bottom: 60px;
}

.AvailableRow{
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvailableRowT{
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvailableRow img{
  width: 80%;
  margin-bottom: 30px;
}

.AvailableRowT img{
  width: 100%;
  margin-bottom: 30px;
}

.WhyBuy{
  margin-bottom: 60px;
}

.WhyBuy h2{
  text-align: center;
  font-weight: 800;
  padding-bottom: 10px;
}

.WhyBuy p{
  text-align: center;
  font-weight: 800;
  padding-bottom: 40px;
}

.WhyBuyRow img{
  width: 100%;
}

a{
  text-decoration: none;
  color: #4794b5;
}

@media screen and (max-width: 650px) {
  .HeroMain {
    padding: 60px 10px;
  }

  .scndModalCol {
    margin-top: 20px;
  }

  .allWallet {
    border-right: none;
    border-bottom: 3px solid #d52232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .spaceBox {
    width: 270px;
    margin-left: 15px;
  }

  .supply {
    margin-top: 7rem;
  }

  .Selected{
    padding: 10px 0px 10px 10px;
  }
  .connectWallet{
    padding: 10px 35px;
  }

  .blue{
    padding: 20px 25px 0 25px;
  }

  .supply img {
    margin: 10px 0;
    width: -webkit-fill-available;
  }

  .supply h2 {
    font-weight: bold;
  }

  .plySpn {
    width: max-content;
    font-size: 11px;
  }

  .btnGroupMain {
    margin-top: 20px;
    margin-bottom: 80px;
  }

  .evmChains button {
    width: 80%;
  }

  .chainHeader {
    padding: 40px 10px;
  }

  .solanaChain button {
    width: 80%;
  }

  .AvailableRow img, .AvailableRowT img{
    width: 210px;
    margin-bottom: 30px;
  }

  .row {
    overflow-x: hidden;
  }

  .whyBuyEmgHXN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .whyBuyEmgHXN img {
    width: 60% !important;
  }

  .Available{
    margin-top: 60rem;
  }

  .Available h2{
    font-size: 2rem;
  }

  .title h1{
    padding-bottom: 80px;
    font-size: 3rem;
  }

  .resMay{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cahinCard,
  .cahinCardScnd,
  .cahinCardForth,
  .cahinCardFifth {
    margin-bottom: 30px;
  }

  .playReceive h6 {
    text-align: center;
  }

  .spaceW {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selectMain {
    width: 270px !important;
    margin-left: 19px;
  }

  .minmmm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rola {
    display: none;
  }
}

@media (min-width: 651px) and (max-width: 950px) {
  .btnGroupMain {
    margin-bottom: 70px;
  }

  .supply {
    margin-top: 17rem;
  }

  .cahinCard,
  .cahinCardScnd,
  .cahinCardForth,
  .cahinCardFifth {
    margin-bottom: 30px;
  }

  .rola {
    display: none;
  }
}

@media (min-width: 950px) and (max-width: 10000px) {
  .ChainBox {
    width: fit-content;
  }
}
