.bgHdr{
    background-color: #B4D2DF !important;
}

.dropdown-menu{
    --bs-dropdown-bg: #B4D2DF !important;
}

.nav-link{
    color: black !important;
    font-weight: 600 !important;
}

.lognBtn{
    border: 1px solid #fff !important;
    padding: 5px 25px 7px 25px !important;
    margin-left: 10px;
    border-radius: 27px !important;
    background-color: white !important;
    text-align: center;
    transition: all ease 0.5s;
}

.transcroll{
    box-shadow: 0 0.125rem 1rem 3px rgba(0, 0, 0, 0.1);
    background-color: #B4D2DF !important;
    z-index: 10;
}

@media screen and (max-width: 950px) {
    .lognBtn{
        margin-left: 10px;
    }
}