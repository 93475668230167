

.HowToBuyTxt{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    text-align: left;
}

.HowToBuyTxt h1{
    font-weight: 800;
    font-size: 3.5rem;
}

.HowToBuyTxt p{
    font-weight: 700;
}

.HowToBuyEmg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}

.pinkBox{
    background-color: #DB9EA8;
    border-radius: 2rem;
    padding: 10px 18px 5px 18px;
    width: fit-content;
    margin-bottom: 10px;
}

.pinkBox h6{
    font-weight: 800;
}

.step1Text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    text-align: right;
}

.step1STText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
}

.step1STText h3{
    font-weight: bold;
}

.step1STText p{
    font-weight: 600;
}

.step1Text h3{
    font-weight: bold;
}

.step1Text p{
    font-weight: 600;
}

.greyStepBox{
    background-color: #8AAFBF;
    border-radius: 2rem;
    padding: 10px 18px 5px 18px;
    width: fit-content;
    margin-bottom: 10px;
}

.greyStepBox h6{
    font-weight: 800;
}

.step2MainEmg{
    display: flex;
    justify-content: center;
    align-items: center;
}

.step6MainEmg{
    display: flex;
    justify-content: end;
    align-items: end;
}

.lineMain{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}

.line {
    width: 15px;
    height: 2500px;
    background-color: #9BC1D2;
    position: absolute;
    border-radius: 2rem;
  }

  .line2 {
    width: 15px;
    height: 720px;
    background-color: #9BC1D2;
    position: absolute;
    border-radius: 2rem;
  }

@media screen and (max-width: 950px) {
    .HowToBuyTxt{
        height: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .HowToBuyEmg{
        padding-top: 0;
    }

    .line, .line2{
        display: none;
    }

    .step1Main{
        margin-top: 4rem;
    }

    .step1MainRes{
        display: flex;
        flex-direction: column-reverse;
    }

    .step6MainEmg, .step1MainEmg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .step8Res, .step2MainEmg{
        width: 85%;
    }
}