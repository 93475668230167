footer {
  padding: 40px 0 0px 0;
}

.footer-top-1 > h4 {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
}

.footer-top-1 > .images {
  display: flex;
  align-items: center;
  gap: 19px;
  flex-wrap: wrap;
  margin-top: 15px;
}

.footer-top-2 .box-2 {
  margin-top: 0 !important;
}

.footer-top-2 > .box-2 {
  display: block !important;
}

.footer-top-2 > .box-2 span {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  color: #6b7280;
}

.footer-top-2 > .box-2 p {
  font-family: "Kanit", sans-serif !important;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  color: #000;
}

.box-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-top-2 {
  background-color: #a3c5d4;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 29px;
}

.b1 {
  width: 98%;
  height: 1px;
  background-color: #000;
  opacity: 0.5;
  margin: auto;
  margin-top: 20px;
}

.footerp1 {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #000;
}

.footerp1 {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #000;
}

.footerh6 {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #000;
}

.footer-anker {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
  color: #374151;
  text-decoration: none;
}

.footer-bottom-inner-1 {
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;
  gap: 10px;
}

.footer-box {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 9px 0;
}

.footer-box > p {
  margin-bottom: 0;
  width: 250px;
}

.footerp1 {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #000;
}

@media screen and (max-width: 1200px) {
  .footer-top-2 {
    margin-top: 30px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1400px) {
  .footer-top-2 {
    gap: 17px;
  }
}
